import validateRules from '@/services/validateRules';
import RESET_PASSWORD_MUTATION from '@/graphql/mutations/resetPassword.gql';
import Btn from '@/components/Btn/Btn.vue';

export default {
  name: 'ResetPassword',
  components: { Btn },
  data: () => ({
    RESET_PASSWORD_MUTATION,
    step: 1,
    email: '',
    emailRules: [v => !!v || 'Email is Required', validateRules.email],
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onDone() {
      this.step = 2;
    },
  },
};
