import Vue from 'vue';

export const errorFilter = function(value) {
  if (!value) return '';
  return value.includes('code 400')
    ? 'Error... Something went wrong! Please try it later'
    : value.replace('Network error: ', '').replace('GraphQL error: ', '');
};

Vue.filter('errorMsg', errorFilter);
