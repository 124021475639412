import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal.vue';
import Modal from '@/components/Modal/Modal.vue';
export default {
  name: 'ActionsMenu',
  components: { ConfirmationModal, Modal },
  props: {
    actions: {
      type: Array,
    },
  },
  data: () => ({
    menuOpen: false,
  }),
  methods: {
    onItemClick(item) {
      this.menuOpen = false;
      if (item.onClick) item.onClick();
    },
    onModalClose() {
      this.menuOpen = false;
    },
  },
};
