<template>
  <div class="client-details">
    <client :clientId="$route.params.clientId"></client>
  </div>
</template>
<script>
import Client from '@/components/Client/Client.vue';
export default {
  name: 'ClientDetails',
  components: { Client },
};
</script>
