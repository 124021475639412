import COMPONENTS_AND_OPTIONS from '@/graphql/queries/componentsAndOptions.gql';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import CLONE_EXPENDITURE_OPTIONS_MUTATION from '@/graphql/mutations/cloneExpenditureOptions.gql';
import UPDATE_EXPENDITURE_OPTIONS_MUTATION from '@/graphql/mutations/updateExpenditureOptions.gql';
import DELETE_EXPENDITURE_OPTIONS_MUTATION from '@/graphql/mutations/deleteExpenditureOptions.gql';
import PERMISSIONS from '@/enums/permissions';
import accessByPermissions from '@/services/userPermissions';
import ActionsMenu from '@/components/ActionsMenu/ActionsMenu.vue';
import Btn from '@/components/Btn/Btn.vue';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal.vue';
import DataTableVS from '@/components/DataTableVS/DataTableVS.vue';
import eventHub from '@/utils/eventHub';
import Modal from '@/components/Modal/Modal.vue';
import MutateOptionsModal from './MutateOptionsModal/MutateOptionsModal.vue';
import { COMPONENT_STATUS } from '@/enums/componentStatus';

export default {
  name: 'ExpendituresOptions',
  components: { Btn, ConfirmationModal, MutateOptionsModal, Modal, ActionsMenu, 'data-table-vs': DataTableVS },
  props: {
    plan: {
      type: Object,
      default: null,
    },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
    componentsAndOptions: {
      query: COMPONENTS_AND_OPTIONS,
      variables() {
        return {
          planId: (this.plan && this.plan.id) || null,
          status: COMPONENT_STATUS.OPEN,
        };
      },
      skip() {
        return !this.plan || !this.plan.id;
      },
      error(error) {
        this.error = error.networkError ? { ...error, message: 'Something went wrong! Could not load expenditure options.' } : error;
      },
      fetchPolicy: 'cache-first',
    },
  },
  data: () => ({
    DELETE_EXPENDITURE_OPTIONS_MUTATION,
    currentUser: null,
    componentsAndOptions: null,
    error: null,
  }),
  computed: {
    loading() {
      return this.$apollo.queries.componentsAndOptions.loading;
    },
    headers() {
      return [
        {
          text: 'Name',
          value: 'name',
          type: 'text',
          dataType: 'string',
          width: '150px',
        },
        {
          text: 'Date',
          value: 'createdAt',
          type: 'date',
          dataType: 'date',
          width: '150px',
        },
        {
          text: 'Creator',
          value: 'creator',
          type: 'text',
          dataType: 'string',
          width: '150px',
        },
        {
          text: 'Component Budget',
          value: 'componentBudget',
          type: 'text',
          dataType: 'string',
          width: '150px',
        },
        {
          text: 'Edited Components',
          value: 'editedComponents',
          type: 'text',
          dataType: 'string',
          width: '150px',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '40px',
          fixed: true,
          permissions: [PERMISSIONS.EDIT_EXPENDITURE_OPTIONS, PERMISSIONS.DELETE_EXPENDITURE_OPTIONS],
        },
      ];
    },
    displayedItems() {
      return this.expenditureOptions.map(optionSet => {
        let components;
        let componentBudget = 0;
        let editedComponents = 0;
        try {
          components = JSON.parse(optionSet.options).components || [];
        } catch {
          components = [];
        }
        components.forEach(component => {
          componentBudget += component.budgetAmount;
        });
        editedComponents = components.length;
        return {
          id: optionSet.id,
          name: optionSet.name,
          createdAt: optionSet.createdAt,
          creator: optionSet.creator?.username,
          componentBudget,
          editedComponents,
          actions: [
            accessByPermissions(PERMISSIONS.EDIT_EXPENDITURE_OPTIONS, this.currentUser) && {
              name: 'Edit',
              modal: {
                type: 'common',
                props: {
                  title: 'Rename Option',
                  width: '380px',
                },
                content: {
                  componentName: MutateOptionsModal,
                  props: {
                    mutation: UPDATE_EXPENDITURE_OPTIONS_MUTATION,
                    onMutationCallback: this.onUpdateOptions(),
                    btnName: 'Rename',
                    prevName: optionSet.name,
                    variables: {
                      id: optionSet.id,
                    },
                  },
                },
              },
            },
            accessByPermissions(PERMISSIONS.EDIT_EXPENDITURE_OPTIONS, this.currentUser) && {
              name: 'Clone',
              modal: {
                type: 'common',
                props: {
                  title: 'Clone option',
                  width: '380px',
                },
                content: {
                  componentName: MutateOptionsModal,
                  props: {
                    btnName: 'Clone',
                    prevName: optionSet.name + ' (Cloned)',
                    mutation: CLONE_EXPENDITURE_OPTIONS_MUTATION,
                    onMutationCallback: this.onCloneComponent(),
                    variables: {
                      id: optionSet.id,
                    },
                  },
                },
              },
            },
            accessByPermissions(PERMISSIONS.DELETE_EXPENDITURE_OPTIONS, this.currentUser) && {
              name: 'Delete',
              modal: {
                props: {
                  mutation: DELETE_EXPENDITURE_OPTIONS_MUTATION,
                  variables: { id: optionSet.id },
                  title: 'Delete option',
                  message: 'Are you sure you want to delete options?',
                  okBtn: { label: 'Delete' },
                  update: this.onDelete(optionSet),
                },
              },
            },
          ],
        };
      });
    },
    displayedHeaders() {
      return this.headers.filter(h => !h.permissions || h.permissions.some(p => accessByPermissions(p, this.currentUser)));
    },
    expenditureOptions() {
      return this.componentsAndOptions?.expenditureOptions ?? [];
    },
  },
  methods: {
    showPlanningWindow(item) {
      const optionSet = this.expenditureOptions.find(optionSet => optionSet.id == item.id);
      try {
        eventHub.$emit('show-option', {
          ...optionSet,
          options: JSON.parse(optionSet.options),
        });
      } catch {
        eventHub.$emit('show-snackbar', { color: 'error', text: 'Error! Something went wrong... Please try later!' });
      }
    },
    readQuery(store) {
      return store.readQuery({
        query: COMPONENTS_AND_OPTIONS,
        variables: {
          planId: this.plan.id,
          status: COMPONENT_STATUS.OPEN,
        },
      });
    },
    writeQuery(store, data) {
      return store.writeQuery({
        query: COMPONENTS_AND_OPTIONS,
        variables: {
          planId: this.plan.id,
          status: COMPONENT_STATUS.OPEN,
        },
        data,
      });
    },
    onUpdateOptions() {
      return (store, { data: { updateExpenditureOptions } }) => {
        if (updateExpenditureOptions) {
          const data = this.readQuery(store);
          if (data) {
            const optionSet = data.componentsAndOptions.expenditureOptions.find(option => option.id === updateExpenditureOptions.id);
            optionSet.name = updateExpenditureOptions.name;
            this.writeQuery(store, data);
          }
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: 'Option name was changed successfully!',
          });
        }
      };
    },
    onCloneComponent() {
      return (store, { data: { cloneExpenditureOptions } }) => {
        if (cloneExpenditureOptions) {
          const data = this.readQuery(store);
          if (data) {
            data.componentsAndOptions.expenditureOptions.unshift(cloneExpenditureOptions);
            this.writeQuery(store, data);
          }
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: 'Option was cloned successfully!',
          });
        }
      };
    },
    onDelete(item) {
      return (store, { data: { deleteExpenditureOptions } }) => {
        if (deleteExpenditureOptions) {
          const data = this.readQuery(store);
          if (data) {
            data.componentsAndOptions.expenditureOptions = data.componentsAndOptions.expenditureOptions.filter(option => {
              return option.id !== item.id;
            });
            this.writeQuery(store, data);
          }
          eventHub.$emit('show-snackbar', { color: 'success', text: 'Option was deleted successfully!' });
        }
      };
    },
  },
};
