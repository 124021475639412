import Btn from '@/components/Btn/Btn.vue';
import eventHub from '@/utils/eventHub';

export default {
  name: 'AppNotification',
  components: { Btn },
  mounted() {
    // adding eventHub listener
    eventHub.$on('show-snackbar', data => {
      const queue = this.queue.filter(i => i.snack);
      if (data.text !== '' && !queue.some(i => i.text === data.text)) {
        queue.push({
          snack: true,
          ...data,
        });
      }
      this.queue = queue;
    });
  },
  beforeDestroy() {
    // removing eventHub listener
    eventHub.$off('show-snackbar');
  },
  data: () => ({
    queue: [],
  }),
  methods: {
    onClose(snack) {
      this.queue = this.queue.filter(i => i.text !== snack.text);
    },
  },
};
