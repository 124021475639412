import Vue from 'vue';
import { VBreadcrumbs } from 'vuetify/lib';
import accessByPermissions from '@/services/userPermissions';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
// Create Base Mixins and Define Custom Properties
const base = Vue.extend({ mixins: [VBreadcrumbs] });
export default base.extend({
  name: 'Breadcrumbs',
  components: {
    VBreadcrumbs,
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    currentUser: null,
  }),
  computed: {
    displayedItems() {
      return this.items.filter(i => {
        return !i.permission || (i.permission && accessByPermissions(i.permission, this.currentUser));
      });
    },
  },
});
