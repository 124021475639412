export default {
  name: 'Alert',
  props: {
    error: { type: Object | Error, default: null },
    align: {
      type: String,
      default: 'left',
      validator: function(value) {
        return ['right', 'left', 'center'].includes(value);
      },
    },
  },
};
