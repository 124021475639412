import Login from '@/components/Login/Login.vue';
export default {
  name: 'UnAuthAppLayout',
  components: { Login },
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 220;
        case 'sm':
          return 320;
        case 'md':
          return 420;
        case 'lg':
          return 520;
        case 'xl':
          return 620;
        default:
          return 620;
      }
    },
  },
};
