export const COMPONENTS_REPORT_TYPE = {
  ITEMIZED_LIST_OF_FACILITY_COSTS: 'itemizedListOfFacilityCosts',
  SUMMARY_BY_FISCAL_YEAR: 'summaryByFiscalYear',
  DETAILED_SUMMARY_OF_FACILITY_COSTS: 'detailedSummaryOfFacilityCosts',
  SUMMARY_BY_FACILITY: 'summaryByFacility',
  EXPENDITURE_SPREADSHEET: 'expenditureSpreadsheet',
  GAPS_ANALYSIS: 'gapsAnalysis',
  SUMMARY_BY_FUNDING_SOURCE: 'summaryByFundingSource',
};

export const COMPONENTS_REPORT_TYPE_LABELS = {
  [COMPONENTS_REPORT_TYPE.ITEMIZED_LIST_OF_FACILITY_COSTS]: 'Itemized List of Facility Costs',
  [COMPONENTS_REPORT_TYPE.SUMMARY_BY_FISCAL_YEAR]: 'Summary by Fiscal Year',
  [COMPONENTS_REPORT_TYPE.DETAILED_SUMMARY_OF_FACILITY_COSTS]: 'Detailed Summary of Facility Costs',
  [COMPONENTS_REPORT_TYPE.SUMMARY_BY_FACILITY]: 'Summary by Facility',
  [COMPONENTS_REPORT_TYPE.EXPENDITURE_SPREADSHEET]: 'Expenditure Spreadsheet',
  [COMPONENTS_REPORT_TYPE.GAPS_ANALYSIS]: 'Graphs - Gaps Analysis (Funding vs. needs)',
  [COMPONENTS_REPORT_TYPE.SUMMARY_BY_FUNDING_SOURCE]: 'Summary by Funding source',
};
