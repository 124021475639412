import { VFileInput } from 'vuetify/lib';

export default {
  name: 'Upload',
  components: {
    VFileInput,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: File | Array | null,
  },
};
