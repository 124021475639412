import COMPONENTS_OF_NEEDS from '@/graphql/queries/componentsOfNeed.gql';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import DELETE_COMPLETED_COMPONENT_MUTATION from '@/graphql/mutations/deleteComponentOfNeed.gql';
import RESTORED_COMPONENT_OF_NEED_MUTATION from '@/graphql/mutations/restoreComponentOfNeed.gql';
import PERMISSIONS from '@/enums/permissions';
import accessByPermissions from '@/services/userPermissions';
import ActionsMenu from '@/components/ActionsMenu/ActionsMenu.vue';
import Btn from '@/components/Btn/Btn.vue';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal.vue';
import componentOfNeedsHeaders from '@/enums/componentOfNeedsHeaders';
import DataTableVS from '@/components/DataTableVS/DataTableVS.vue';
import eventHub from '@/utils/eventHub';
import Modal from '@/components/Modal/Modal.vue';
import { COMPONENT_STATUS } from '@/enums/componentStatus';
import { getBaseAmount, getBudgetAmount, getComponentSubtotal } from '@/services/componentBudget';
import ComponentOfNeedForm from '@/components/ComponentOfNeedForm/ComponentOfNeedForm.vue';

export default {
  name: 'CompletedComponents',
  components: { Btn, ComponentOfNeedForm, ConfirmationModal, Modal, ActionsMenu, 'data-table-vs': DataTableVS },
  props: {
    facilities: { type: Array, default: () => [] },
    plan: {
      type: Object,
      default: null,
    },
    showActions: { type: Boolean, default: true },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
    componentsOfNeed: {
      query: COMPONENTS_OF_NEEDS,
      variables() {
        return {
          planId: (this.plan && this.plan.id) || null,
          status: COMPONENT_STATUS.COMPLETED,
        };
      },
      skip() {
        return !this.plan || !this.plan.id;
      },
      error(error) {
        this.error = error.networkError ? { ...error, message: 'Something went wrong! Could not load completed components.' } : error;
      },
    },
  },
  data: () => ({
    DELETE_COMPLETED_COMPONENT_MUTATION,
    currentUser: null,
    componentsOfNeed: null,
    error: null,
  }),
  created() {
    eventHub.$on('refresh-components-of-need', () => {
      this.$apollo.queries.componentsOfNeed.refresh();
    });
  },
  computed: {
    loading() {
      return this.$apollo.queries.componentsOfNeed.loading;
    },
    headers() {
      return [
        ...componentOfNeedsHeaders.COMPLETED,
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '53px',
          fixed: true,
          permissions: [PERMISSIONS.DELETE_PLAN_COMPONENT],
        },
      ];
    },
    displayedItems() {
      return this.componentsOfNeed
        ? this.componentsOfNeed.map(component => {
            const { quantityOfComponents, costPerUnit, yearForImprovement, softCost, customSoftCost } = component;
            const { regionalCost, planStartYear, inflationCostEscalation, softCostPercentage } = this.plan.financialInfo;

            const baseAmount = getBaseAmount(quantityOfComponents, costPerUnit, regionalCost);
            const componentSubtotal = getComponentSubtotal(baseAmount, planStartYear, yearForImprovement, inflationCostEscalation);
            const budgetAmount = getBudgetAmount(componentSubtotal, customSoftCost ?? softCostPercentage, softCost);
            const row = {
              ...component,
              facilityName: (component.facility && component.facility.name) || '',
              componentName: (component.component && component.component.name) || '',
              budgetAmount: budgetAmount,
            };
            if (this.showActions) {
              row.actions = [
                accessByPermissions(PERMISSIONS.EDIT_PLAN_COMPONENT, this.currentUser) && {
                  name: 'Edit',
                  modal: {
                    type: 'common',
                    props: {
                      title: 'Edit Component',
                      width: '1000px',
                    },
                    content: {
                      componentName: ComponentOfNeedForm,
                      props: {
                        btnLabel: 'Update',
                        plan: this.plan,
                        facilities: this.facilities,
                        formData: component,
                        isEdit: true,
                      },
                    },
                  },
                },
                accessByPermissions(PERMISSIONS.EDIT_PLAN_COMPONENT, this.currentUser) && {
                  name: 'Restore',
                  modal: {
                    props: {
                      mutation: RESTORED_COMPONENT_OF_NEED_MUTATION,
                      variables: { id: component.id },
                      title: 'Restore Component',
                      message: 'Are you sure you want to restore the component?',
                      okBtn: { label: 'Restore' },
                      update: this.onRestore(component),
                    },
                  },
                },
                accessByPermissions(PERMISSIONS.DELETE_PLAN_COMPONENT, this.currentUser) && {
                  name: 'Delete',
                  modal: {
                    props: {
                      mutation: DELETE_COMPLETED_COMPONENT_MUTATION,
                      variables: { id: component.id },
                      title: 'Delete Component',
                      message: 'Are you sure you want to delete the component?',
                      okBtn: { label: 'Delete' },
                      update: this.onDelete(component),
                    },
                  },
                },
              ];
            }
            return row;
          })
        : [];
    },
    displayedHeaders() {
      return this.headers.filter(h => !h.permissions || h.permissions.some(p => accessByPermissions(p, this.currentUser)));
    },
    showEditBtn() {
      return accessByPermissions(PERMISSIONS.EDIT_PLAN_COMPONENT, this.currentUser);
    },
    showDeleteBtn() {
      return accessByPermissions(PERMISSIONS.DELETE_PLAN_COMPONENT, this.currentUser);
    },
  },
  methods: {
    onDelete(item) {
      return (store, { data: { deleteComponentOfNeed } }) => {
        if (deleteComponentOfNeed) {
          const data = store.readQuery({
            query: COMPONENTS_OF_NEEDS,
            variables: {
              planId: this.plan.id,
              status: item.status,
            },
          });
          data.componentsOfNeed = data.componentsOfNeed.filter(component => {
            return component.id !== item.id;
          });
          store.writeQuery({
            query: COMPONENTS_OF_NEEDS,
            variables: {
              planId: this.plan.id,
              status: item.status,
            },
            data,
          });
          eventHub.$emit('show-snackbar', { color: 'success', text: 'Component was deleted successfully!' });
        }
      };
    },
    onRestore() {
      return (store, { data: { restoredComponentOfNeed } }) => {
        if (restoredComponentOfNeed) {
          let data = store.readQuery({
            query: COMPONENTS_OF_NEEDS,
            variables: {
              planId: this.plan.id,
              status: COMPONENT_STATUS.COMPLETED,
            },
          });
          if (data) {
            data.componentsOfNeed = data.componentsOfNeed.filter(c => c.id !== restoredComponentOfNeed.id);
            store.writeQuery({
              query: COMPONENTS_OF_NEEDS,
              variables: {
                planId: this.plan.id,
                status: COMPONENT_STATUS.COMPLETED,
              },
              data,
            });
          }
          try {
            data = store.readQuery({
              query: COMPONENTS_OF_NEEDS,
              variables: {
                planId: this.plan.id,
                status: restoredComponentOfNeed.status,
              },
            });
            if (data) {
              data.componentsOfNeed.unshift(restoredComponentOfNeed);
              store.writeQuery({
                query: COMPONENTS_OF_NEEDS,
                variables: {
                  planId: this.plan.id,
                  status: restoredComponentOfNeed.status,
                },
                data,
              });
            }
          } catch (e) {
            //
          }
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: `Status of the component was changed successfully!`,
          });
        } else {
          eventHub.$emit('show-snackbar', {
            color: 'error',
            text: `Error! Something went wrong... Please try later!`,
          });
        }
      };
    },
  },
};
