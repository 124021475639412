var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{attrs:{"items":_vm.displayedItems,"headers":_vm.displayedHeaders,"height":"611px","item-key":"id","loading":_vm.loading,"show-select":_vm.showSelect,"select-label":"Compare","noDataText":"No Clients"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.canViewDetails)?_c('router-link',{attrs:{"to":{ name: 'client-details', params: { clientId: item.id } }}},[_vm._v(_vm._s(item.name))]):[_vm._v(_vm._s(item.name))]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-actions-bar"},[(_vm.showDeleteBtn)?_c('confirmation-modal',{attrs:{"mutation":_vm.DELETE_CLIENT_MUTATION,"variables":{ id: item.id },"title":"Delete Client","ok-btn":{ label: 'Delete' },"update":_vm.onDelete(item)},scopedSlots:_vm._u([{key:"message",fn:function(){return [(item.documents.length > 0)?_c('p',{staticClass:"alert-msg"},[_vm._v("This client has attached documents.")]):_vm._e(),_c('p',[_vm._v("Are you sure you want to delete the client?")])]},proxy:true},{key:"default",fn:function(ref){
var modalOn = ref.on;
var attrsModal = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action",attrs:{"small":""}},'v-icon',attrsModal,false),modalOn),[_vm._v(" far fa-trash-alt ")])]}}],null,true)}):_vm._e()],1)]}}]),model:{value:(_vm.selectedState),callback:function ($$v) {_vm.selectedState=$$v},expression:"selectedState"}})}
var staticRenderFns = []

export { render, staticRenderFns }