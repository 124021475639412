import InputList from '@/components/InputList/InputList.vue';

export default {
  name: 'FileInputList',
  components: { InputList },
  props: {
    value: Array,
    max: Number,
    loading: Boolean,
    modal: Boolean,
  },
  computed: {
    list() {
      const result = Array.isArray(this.value)
        ? this.value.map(item => {
            if (typeof item.value === 'string') {
              item.value = new File([''], item.value, { type: '' });
            }
            return item.value instanceof File ? item : undefined;
          })
        : [
            {
              value: new File([''], '', { type: '' }),
            },
          ];
      return result.length
        ? result
        : [
            {
              value: new File([''], '', { type: '' }),
            },
          ];
    },
  },
  methods: {
    remove(index) {
      this.list.splice(index, 1);
      this.$emit('remove', index);
      this.input();
    },
    input(list) {
      this.$emit('input', list);
    },
    change(index, file) {
      if (file instanceof File) {
        this.list[index].value = file;
        this.input(this.list);
        this.$emit('change', {
          index,
          value: this.list[index],
        });
      }
    },
  },
};
