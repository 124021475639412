import NAV_ITEMS from './PrimaryNav.config';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import accessByPermissions from '@/services/userPermissions';

export default {
  name: 'PrimaryNav',
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-first',
    },
  },
  data() {
    return {
      items: NAV_ITEMS,
      displayedItems: [],
      currentUser: null,
    };
  },
  watch: {
    currentUser(value) {
      if (!value) return;
      this.displayedItems = this.items.filter(item => {
        const noRestrictionByPermissions = !item.permission || accessByPermissions(item.permission, value);
        const noRestrictionByRoles = !item.roles || item.roles.find(r => value.role.name === r);
        if (item.params) {
          const params = item.params.reduce((pars, param) => {
            let paramVal = value[param];
            if (param === 'clientId') {
              paramVal = value.client ? value.client.id : '';
            }
            pars[param] = paramVal;
            return pars;
          }, {});
          item.to = { ...item.to, params: params };
        }
        return noRestrictionByPermissions && noRestrictionByRoles;
      });
    },
  },
};
