import Btn from '@/components/Btn/Btn.vue';
import ExpansionPanelHeader from '@/components/ExpansionPanelHeader/ExpansionPanelHeader.vue';
import GeneralInfo from './GeneralInfo/GeneralInfo.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import FileList from '@/components/FileList/FileList.vue';
import Contacts from '@/components/Clients/ClientForm/Contacts/Contacts.vue';
import Facilities from '@/components/Clients/ClientForm/Facilities/Facilities.vue';
import Documents from './Documents/Documents.vue';
import Dashboard from './Dashboard/Dashboard.vue';
import EnergyUsage from './EnergyUsage/EnergyUsage.vue';
import FinancialInfo from './FinancialInfo/FinancialInfo.vue';
import Components from './Components/Components.vue';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import eventHub from '@/utils/eventHub';

export default {
  name: 'ClientInfo',
  components: {
    Btn,
    ExpansionPanelHeader,
    GeneralInfo,
    Dashboard,
    DataTable,
    FileList,
    Contacts,
    Facilities,
    Documents,
    EnergyUsage,
    FinancialInfo,
    Components,
  },
  props: {
    client: { type: Object, default: null },
    hidePlans: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-first',
    },
  },
  data: () => ({
    activeComponentsTab: 0,
    currentUser: null,
    error: null,
    selectedTab: null,
    contactInfoLabels: [
      {
        label: 'Name',
        value: 'name',
      },
      {
        label: 'Job Title',
        value: 'jobTitle',
      },
      {
        label: 'Phone',
        labelName: 'type.name', // key in model
        value: 'phones',
        type: 'list',
        valueName: 'phone', // key in model
      },
      {
        label: 'Email',
        value: 'email',
      },
      {
        label: 'Facility/Location of Contact',
        value: 'location',
      },
      {
        label: 'Number of Year With Employee',
        value: 'countYearsTogether',
      },
    ],
    planHeaders: [
      {
        text: 'Name',
        value: 'name',
        type: 'text',
        dataType: 'string',
        width: '150px',
        fixed: true,
      },
      {
        text: 'Date of Creation',
        value: 'dateOfCreation',
        type: 'date',
        dataType: 'date',
        width: '150px',
      },
      { text: 'Plan Type', value: 'planType', type: 'select', dataType: 'string', width: '100px' },
      { text: 'Creator', value: 'creatorName', type: 'select', dataType: 'string', width: '120px' },
      { text: 'Editor', value: 'editorName', type: 'select', dataType: 'string', width: '150px' },
      { text: 'Last Updated', value: 'updatedAt', type: 'date', dataType: 'date', width: '180px' },
    ],
    infoTabs: [
      { name: 'Dashboard', content: 'dashboard' },
      { name: 'General', content: 'general' },
      { name: 'Contacts', content: 'contacts' },
      { name: 'Facilities', content: 'facilities' },
      { name: 'Documents & Links', content: 'documents' },
      { name: 'Financial', content: 'financial' },
      { name: 'Energy Usage', content: 'energy' },
      { name: 'Expenditures', content: 'components' },
    ],
  }),
  watch: {
    selectedTab() {
      if (this.selectedTab === 0) {
        eventHub.$emit('to-to-dashboard');
      }
    },
  },
  computed: {
    clientId() {
      return this.client?.id || null;
    },
    plan() {
      return this.client?.plans ? this.client.plans[0] : null;
    },
    planId() {
      return this.plan?.id || null;
    },
    financialInfo() {
      return this.plan?.financialInfo || null;
    },
    canViewPlans() {
      return accessByPermissions(PERMISSIONS.VIEW_PLAN, this.currentUser);
    },
    facilities() {
      return this.client ? this.client.facilities : [];
    },
    displayedPlans() {
      return this.client && this.client.plans
        ? this.client.plans.map(plan => {
            return {
              ...plan,
              creatorName: (plan.createdBy && plan.createdBy.username) || '',
              planType: (plan.financialInfo && plan.financialInfo.typeOfPlan && plan.financialInfo.typeOfPlan.name) || '',
              editorName: (plan.editedBy && plan.editedBy.username) || '',
              canViewDetails: accessByPermissions(PERMISSIONS.VIEW_PLAN, this.currentUser),
            };
          })
        : [];
    },
  },
  mounted() {
    eventHub.$on('go-to-tab', tabName => {
      const index = this.infoTabs.findIndex(item => item.name === tabName);
      if (index + 1) {
        this.selectedTab = index;
      }
    });
    eventHub.$on('go-to-expenditures', componentTabId => {
      const index = this.infoTabs.findIndex(item => item.name === 'Expenditures');
      if (index + 1) {
        this.selectedTab = index;
        this.activeComponentsTab = componentTabId ?? 0;
      }
    });
  },
};
