import CREATE_COMPONENTS_BY_XLSX from '@/graphql/mutations/createComponentsByXlsx.gql';
import Btn from '@/components/Btn/Btn.vue';

export default {
  name: 'ImportComponentForm',
  props: {
    fundingSourceId: { type: String },
    planId: { type: String },
  },
  components: {
    Btn,
  },
  data: () => ({
    importedFile: null,
    dragAndDropCapable: false,
    isFileSend: false,
    sendError: null,
    components: null,
  }),
  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    if (this.dragAndDropCapable) {
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(
        function(evt) {
          this.$refs.fileForm.addEventListener(
            evt,
            function(e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false
          );
        }.bind(this)
      );
      this.$refs.fileForm.addEventListener(
        'drop',
        function(e) {
          if (
            e.dataTransfer.files[e.dataTransfer.files.length - 1].type ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ) {
            this.importedFile = e.dataTransfer.files[e.dataTransfer.files.length - 1];
          }
        }.bind(this)
      );
    }
  },
  computed: {
    isFileUpload() {
      return !!this.importedFile;
    },
  },
  methods: {
    determineDragAndDropCapable() {
      let div = document.createElement('div');
      return ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
    },
    removeFile() {
      this.importedFile = null;
    },
    onLinkClick() {
      this.$refs.fileInput.click();
    },
    onChangeInputFile() {
      this.importedFile = this.$refs.fileInput.files[0];
    },
    onUploadDone() {
      this.$apollo
        .mutate({
          mutation: CREATE_COMPONENTS_BY_XLSX,
          variables: {
            input: {
              documentFile: this.importedFile,
              fundingSourceId: this.fundingSourceId,
            },
          },
          update: (
            store,
            {
              data: {
                createComponentsByXlsx: { components, notValidData },
              },
            }
          ) => {
            if (notValidData) {
              let errors = JSON.parse(notValidData);
              console.log(errors);
              let errorText = '';
              for (let key in errors) {
                if (key === 'notFoundFacilities') {
                  errorText += (errorText ? '<br>' : '') + 'There are no Facilities:<br>';
                  errorText += errors[key].join(', ');
                }
                if (key === 'notFoundComponents') {
                  errorText += (errorText ? '<br>' : '') + 'There is mismatched between Database and Components:<br>';
                  errorText += errors[key].join(', ');
                }
                if (key === 'notFoundSystems') {
                  errorText += (errorText ? '<br>' : '') + 'There is mismatched between Database and Systems:<br>';
                  errorText += errors[key].join(', ');
                }
                if (key === 'notFoundPairs') {
                  errorText += (errorText ? '<br>' : '') + 'There are no Systems and Components in Database:<br>';
                  errorText += errors[key].map(element => '- ' + element.system + ' and ' + element.component + '<br>').join('');
                }
              }
              this.sendError = errorText;
              this.$emit('import-finish', { header: 'Import Failed!', width: 573, close: false });
            } else if (components) {
              this.components = components;
              this.$emit('import-finish', { header: 'Import Complete!', width: 573, close: false });
            }
          },
        })
        .then(() => {
          this.isFileSend = true;
        })
        .catch(error => {
          this.isFileSend = true;
          this.sendError = error.graphQLErrors[0]?.message || error.message;
          this.$emit('import-finish', { header: 'Import Failed!', width: 573, close: false });
        });
    },
    onClickDone() {
      this.$emit('import-finish', { header: 'Import Components', width: 480, close: true, components: this.components });
      this.$emit('done');
    },
  },
};
