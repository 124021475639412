export const getBaseAmount = (quantity, costPerUnit, regionalCost) => {
  if (quantity !== null && costPerUnit !== null && regionalCost !== null) {
    return Math.round((quantity * costPerUnit * regionalCost) / 100);
  } else {
    return 0;
  }
};
export const getComponentSubtotal = (baseAmount, planStartYear, yearForImprovement, inflationCostEscalation) => {
  if (baseAmount !== null && planStartYear !== null && yearForImprovement !== null && inflationCostEscalation !== null) {
    const inflation = Math.pow(1 + inflationCostEscalation / 100, parseInt(yearForImprovement) - parseInt(planStartYear));
    return Math.round(baseAmount * inflation);
  } else {
    return 0;
  }
};
export const getBudgetAmount = (componentSubtotal, softCost, includeSoftCost) => {
  if (componentSubtotal !== null && softCost !== null) {
    return includeSoftCost ? Math.round(componentSubtotal * (1 + softCost / 100)) : componentSubtotal;
  } else {
    return 0;
  }
};

export const getClientBudget = (fundingAmount, fundingEscalation, year, planStartYear) => {
  const escalation = Math.pow(1 + fundingEscalation / 100, parseInt(year) - parseInt(planStartYear));
  return Math.round(fundingAmount * escalation);
};

export const calculateBudget = (component, plan) => {
  const { quantityOfComponents, costPerUnit, yearForImprovement, softCost, customSoftCost } = component;
  const { regionalCost = null, planStartYear = null, inflationCostEscalation = null, softCostPercentage = null } =
    (plan && plan.financialInfo) || {};

  const baseAmount = getBaseAmount(quantityOfComponents, costPerUnit, regionalCost);
  const componentSubtotal = getComponentSubtotal(baseAmount, planStartYear, yearForImprovement, inflationCostEscalation);
  const budgetAmount = getBudgetAmount(componentSubtotal, customSoftCost ?? softCostPercentage, softCost);
  return {
    baseAmount,
    componentSubtotal,
    budgetAmount,
  };
};
