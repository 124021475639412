import { onLogout } from '@/apollo';

export default {
  name: 'UserProfile',
  props: {
    username: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    userInitials() {
      return (
        this.username &&
        this.username
          .split(' ')
          .map(item => item.charAt(0).toUpperCase())
          .join('')
      );
    },
  },
  methods: {
    logout() {
      onLogout();
      this.$router.push({ name: 'login' });
    },
  },
};
