import Modal from '@/components/Modal/Modal.vue';
import Btn from '@/components/Btn/Btn.vue';

export default {
  name: 'InputList',
  components: { Modal, Btn },
  props: {
    value: Array,
    max: Number,
    confirmation: {
      type: String,
      default: 'Are you sure you want to delete the file?',
    },
    title: {
      type: String,
      default: 'Delete Item',
    },
    loading: Boolean,
    modal: Boolean,
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return { deleteIndex: -1 };
  },
  computed: {
    isMax() {
      return this.list.length + 1 > this.maxLength;
    },
    maxLength() {
      return this.max > 0 ? this.max : 10;
    },
    list() {
      const result = Array.isArray(this.value)
        ? this.value.slice(0, this.maxLength).filter(item => item !== undefined)
        : [
            {
              value: '',
            },
          ];
      return result.length
        ? result
        : [
            {
              value: '',
            },
          ];
    },
  },
  methods: {
    del(index) {
      if (this.modal && this.list[index] && this.list[index].name) {
        this.deleteIndex = index;
        this.$refs.modal.modalOpen = true;
      } else {
        this.remove(index);
      }
    },
    approve() {
      this.deleteIndex > -1 && this.remove(this.deleteIndex);
      this.$refs.modal.modalOpen = false;
      this.deleteIndex = -1;
    },
    add() {
      this.list.push({
        value: '',
      });
      this.input();
    },
    remove(index) {
      this.list.splice(index, 1);
      this.$emit('remove', index);
      this.input();
    },
    input() {
      this.$emit('input', this.list.slice());
    },
    change(index, value) {
      this.list[index].value = value;
      this.input();
      this.$emit('change', {
        index,
        value: this.list[index],
      });
    },
  },
};
