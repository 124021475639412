export const formatDate = dateStr => {
  const date = dateStr && new Date(dateStr);
  return date ? date.toLocaleDateString('en-US') : '';
};
export const formatDateUTC = dateStr => {
  const date = dateStr && new Date(dateStr);
  const dateOptions = { timeZone: 'UTC' };

  const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
  return date ? dateFormatter.format(date) : '';
};

// expect date string in "mm/dd/yyyy" format
export const formatDateISO = dateStr => {
  const date = dateStr && new Date(dateStr);
  return date ? date.toISOString() : '';
};
