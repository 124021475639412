import Vue from 'vue';
import { formatTime } from '@/utils/timeFormatter';

function timeFilter(value) {
  if (!value) return '';
  return value === '--' ? value : formatTime(value);
}
Vue.filter('time', timeFilter);

export default timeFilter;
