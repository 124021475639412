import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import '@fortawesome/fontawesome-free/css/all.css';
import provider from './apollo';
import '@/filters';
import { Fragment } from 'vue-fragment';
import Alert from '@/components/Alert/Alert.vue';

Vue.config.productionTip = false;

Vue.component('Fragment', Fragment);
Vue.component('Alert', Alert);

new Vue({
  router,
  vuetify,
  apolloProvider: provider,
  render: h => h(App),
}).$mount('#app');
