<template>
  <unauth-app-layout>
    <reset-password></reset-password>
  </unauth-app-layout>
</template>
<script>
import ResetPassword from '@/components/ResetPassword/ResetPassword.vue';
import UnauthAppLayout from '@/components/UnauthAppLayout/UnauthAppLayout.vue';

export default {
  components: { ResetPassword, UnauthAppLayout },
};
</script>
