import ADD_DOCUMENT_MUTATION from '@/graphql/mutations/addDocument.gql';
import ADD_LINK_MUTATION from '@/graphql/mutations/addLink.gql';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import DELETE_DOCUMENT_MUTATION from '@/graphql/mutations/deleteDocument.gql';
import DELETE_LINK_MUTATION from '@/graphql/mutations/deleteLink.gql';
import PERMISSIONS from '@/enums/permissions';
import UPDATE_DOCUMENT_MUTATION from '@/graphql/mutations/updateDocument.gql';
import UPDATE_LINK_MUTATION from '@/graphql/mutations/updateLink.gql';
import accessByPermissions from '@/services/userPermissions';
import Btn from '@/components/Btn/Btn.vue';
import eventHub from '@/utils/eventHub';
import FileInputList from '@/components/FileInputList/FileInputList.vue';
import InputList from '@/components/InputList/InputList.vue';
import FileList from '@/components/FileList/FileList.vue';
import LinkList from '@/components/LinkList/LinkList.vue';
import Modal from '@/components/Modal/Modal.vue';
import { errorFilter } from '@/filters/errorMsg';
const urlRegexp = /^((?<protocol>http(s)?:\/\/)|(?<short>:\/\/))?(www\.)?(([-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6})|((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)))\b(.*)$/;

export default {
  name: 'Documents',
  components: { Btn, FileInputList, InputList, FileList, LinkList, Modal },
  props: {
    loading: { type: Boolean },
    client: { type: Object },
    showStepButtons: { type: Boolean, default: true },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    fileList: [],
    linkList: [],
    internalLoading: false,
    linkRules: [
      v => {
        return !!v.match(urlRegexp) || 'Link format is incorrect';
      },
    ],
  }),
  watch: {
    client: {
      handler() {
        this.updateFileList();
        this.updateLinkList();
      },
      immediate: true,
    },
  },
  computed: {
    documents() {
      return this.client && Array.isArray(this.client.documents)
        ? this.client.documents
            .map(item => {
              return {
                link: item.documentUrl,
                name: item.name || item.documentUrl,
              };
            })
            .filter(item => item)
        : [];
    },
    links() {
      return this.client && Array.isArray(this.client.links)
        ? this.client.links
            .map(item => {
              return {
                link: item.linkUrl,
                name: item.linkUrl,
              };
            })
            .filter(item => item)
        : [];
    },
    showDocuments() {
      return this.documents && this.documents.length > 0;
    },
    showEditDocuments() {
      return accessByPermissions(PERMISSIONS.MANAGE_DOCUMENTS, this.currentUser);
    },
    showEditLinks() {
      return accessByPermissions(PERMISSIONS.MANAGE_DOCUMENTS, this.currentUser);
    },
    showLinks() {
      return this.links && this.links.length > 0;
    },
  },
  methods: {
    change({ value, index }) {
      if (this.client.id && value.value instanceof File && typeof index === 'number') {
        const variables = {
          clientId: this.client.id,
          documentUrl: '',
          documentFile: value.value,
          name: value.value.name,
        };
        let mutation = ADD_DOCUMENT_MUTATION;
        if (this.client.documents[index] && this.client.documents[index].id) {
          variables.id = this.client.documents[index].id;
          mutation = UPDATE_DOCUMENT_MUTATION;
        }
        this.uploadFile(variables, mutation, index);
      }
    },
    changeLink({ value, index }) {
      if (value.value) {
        const match = value.value.match(urlRegexp);
        if (match && this.client.id && typeof index === 'number') {
          const linkUrl = match.groups.protocol || match.groups.short ? value.value : 'https://' + value.value;
          const variables = {
            clientId: this.client.id,
            linkUrl: linkUrl,
          };
          let mutation = ADD_LINK_MUTATION;
          if (this.client.links[index] && this.client.links[index].id) {
            variables.id = this.client.links[index].id;
            mutation = UPDATE_LINK_MUTATION;
          }
          if (variables && mutation) {
            this.internalLoading = true;
            this.$apollo
              .mutate({
                mutation: mutation,
                variables: {
                  input: variables,
                },
              })
              .then(({ data: { addLink, updateLink } }) => {
                eventHub.$emit('show-snackbar', {
                  color: 'success',
                  text: `Link was uploaded successfully!`,
                });
                this.$set(this.client.links, index, addLink || updateLink);
                this.$emit('submit', addLink || updateLink);
              })
              .catch(e => {
                eventHub.$emit('show-snackbar', {
                  color: 'error',
                  text: errorFilter(e.message),
                });
                this.$set(this.linkList, index, {
                  value: '',
                });
                this.error = e;
              })
              .finally(() => {
                this.internalLoading = false;
              });
          }
        }
      }
    },
    remove(index) {
      if (this.client.id && typeof index === 'number' && this.client.documents[index] && this.client.documents[index].id) {
        this.removeFile(this.client.documents[index].id);
      }
    },
    updateFileList() {
      this.fileList = [];
      this.client &&
        Array.isArray(this.client.documents) &&
        this.client.documents.forEach(item => {
          if (item) {
            if (item instanceof File) {
              this.fileList.push({
                value: item,
              });
            } else if (item.name || item.documentUrl) {
              this.fileList.push({
                value: item.name || item.documentUrl,
                id: item.name.id || item.name || item.documentUrl,
              });
            }
          }
        });
    },
    updateLinkList() {
      this.linkList = [];
      this.client &&
        Array.isArray(this.client.links) &&
        this.client.links.forEach(item => {
          this.linkList.push({
            value: item.linkUrl,
            id: item.id,
          });
        });
    },
    removeFile(id) {
      if (id) {
        this.internalLoading = true;
        this.$apollo
          .mutate({
            mutation: DELETE_DOCUMENT_MUTATION,
            variables: {
              id,
            },
          })
          .then(({ data }) => {
            eventHub.$emit('show-snackbar', {
              color: 'success',
              text: `File was removed successfully!`,
            });
            this.client.documents = this.client.documents.filter(item => item && item.id !== data.deleteDocument);
          })
          .catch(e => {
            this.error = e;
          })
          .finally(() => {
            this.internalLoading = false;
          });
      }
    },
    removeLink(index) {
      let id;
      if (this.client.id && typeof index === 'number' && this.client.links[index] && this.client.links[index].id) {
        id = this.client.links[index].id;
      }
      if (id) {
        this.internalLoading = true;
        this.$apollo
          .mutate({
            mutation: DELETE_LINK_MUTATION,
            variables: {
              id,
            },
          })
          .then(({ data }) => {
            eventHub.$emit('show-snackbar', {
              color: 'success',
              text: `Link was removed successfully!`,
            });
            this.client.links = this.client.links.filter(item => item && item.id !== data.deleteLink);
          })
          .catch(e => {
            this.error = e;
          })
          .finally(() => {
            this.internalLoading = false;
          });
      }
    },
    uploadFile(variables, mutation, index) {
      if (variables && mutation) {
        this.internalLoading = true;
        this.$apollo
          .mutate({
            mutation: mutation,
            variables: {
              input: variables,
            },
          })
          .then(({ data: { addDocument, updateDocument } }) => {
            eventHub.$emit('show-snackbar', {
              color: 'success',
              text: `File was uploaded successfully!`,
            });
            this.$set(this.client.documents, index, addDocument || updateDocument);
            this.$emit('submit', addDocument || updateDocument);
          })
          .catch(e => {
            eventHub.$emit('show-snackbar', {
              color: 'error',
              text: `Error upload file. Max size of the file is 20 MB bytes. Please provide another file.`,
            });
            this.$set(this.fileList, index, {
              value: '',
            });
            this.error = e;
          })
          .finally(() => {
            this.internalLoading = false;
          });
      }
    },
  },
};
