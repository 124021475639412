import Vue from 'vue';
import { VExpansionPanelHeader } from 'vuetify/lib';
// Create Base Mixins and Define Custom Properties
const base = Vue.extend({ mixins: [VExpansionPanelHeader] });
export default base.extend({
  name: 'ExpansionPanelHeader',
  components: {
    VExpansionPanelHeader,
  },
});
