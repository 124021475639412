import Vue from 'vue';
import { VBtn } from 'vuetify/lib';
// Create Base Mixins and Define Custom Properties
const base = Vue.extend({ mixins: [VBtn] });
export default base.extend({
  name: 'Btn',
  components: {
    VBtn,
  },
  props: {
    colorType: {
      type: String | null,
      validator: function(value) {
        return !value || ['primary', 'secondary', 'accent'].indexOf(value) !== -1;
      },
      default: 'primary',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    depressed: {
      type: Boolean,
      default: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorValue() {
      return this.colorType === 'primary' || this.colorType === 'accent' ? 'accent' : '';
    },
    isOutlined() {
      return this.colorType === 'accent';
    },
  },
});
