import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import UserProfile from '@/components/AppHeader/UserProfile/UserProfile.vue';

export default {
  name: 'AppHeader',
  components: { UserProfile },
  data: () => ({
    CURRENT_USER_QUERY,
  }),
  created() {
    this.loadColorTheme();
  },
  methods: {
    setToStorage(value) {
      try {
        window.localStorage.setItem('darkMode', value || '');
      } catch (e) {
        console.warn('set theme error ', e);
      }
    },
    loadColorTheme() {
      try {
        this.$vuetify.theme.dark = Boolean(window.localStorage.getItem('darkMode'));
      } catch (e) {
        console.warn('load theme error ', e);
      }
    },
  },
};
