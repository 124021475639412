import Vue from 'vue';
import ROLES from '@/enums/roles';
function roleFilter(value) {
  if (!value) return '';
  return ROLES[value] || value;
}

Vue.filter('role', roleFilter);

export default roleFilter;
