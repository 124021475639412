import Vue from 'vue';
import VueRouter from 'vue-router';
import ROUTES from './routes.config.js';
import provider, { isAuthenticated } from '@/apollo/apollo';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import PERMISSIONS from '@/enums/permissions';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: ROUTES,
});
router.beforeEach(async (to, from, next) => {
  if (to.name !== 'login' && (to.meta.requiresAuth === undefined || to.meta.requiresAuth) && !isAuthenticated()) next({ name: 'login' });
  else if (isAuthenticated()) {
    const client = provider.defaultClient;
    const {
      data: { currentUser },
    } = await client.query({
      query: CURRENT_USER_QUERY,
    });
    try {
      if (to.name === null && to.path === '/') {
        if (currentUser.permissions.find(p => p.key === PERMISSIONS.VIEW_CLIENTS)) {
          next({ name: 'clients' });
        } else if (currentUser.permissions.find(p => p.key === PERMISSIONS.VIEW_CLIENT) && currentUser.client && currentUser.client.id) {
          next({ name: 'client-details', params: { clientId: currentUser.client.id } });
        }
      } else {
        next();
      }
    } catch (e) {
      next({ name: 'login' });
    }
  } else {
    next();
  }
});
export default router;
