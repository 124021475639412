import { getObjValue } from '@/utils/objExt';
import TruncatedText from '@/components/TruncatedText/TruncatedText.vue';

export default {
  name: 'DefinitionList',
  props: {
    labels: { type: Array, default: [] },
    data: { type: Object, default: null },
    labelWidth: { type: String | Number },
  },
  components: { TruncatedText },
  computed: {
    displayedData() {
      return this.data
        ? this.labels.reduce((d, l) => {
            const value = getObjValue(this.data, l.value);
            const dynamicLabels =
              l.type === 'list'
                ? value && value.length > 0
                  ? value.map(v => {
                      const listItemValue = getObjValue(v, l.labelName);
                      return {
                        label: listItemValue ? `${l.label} (${listItemValue.toLowerCase()})` : l.label,
                        value: getObjValue(v, l.valueName),
                      };
                    })
                  : [
                      {
                        ...l,
                        value: null,
                      },
                    ]
                : null;
            const item = dynamicLabels
              ? dynamicLabels
              : [
                  {
                    ...l,
                    value: value,
                  },
                ];
            d.push(...item);
            return d;
          }, [])
        : [];
    },
  },
};
