import Btn from '@/components/Btn/Btn.vue';
export default {
  name: 'TruncatedText',
  components: {
    Btn,
  },
  props: {
    text: { type: String },
  },
  mounted() {
    this.initObserver();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  data: () => ({
    truncated: false,
    observer: null,
  }),
  methods: {
    initObserver() {
      if (!this.$refs.text) return;
      const containerEl = this.$refs.text.querySelector('span');
      const observer = new IntersectionObserver(
        ([e]) => {
          this.truncated = e.intersectionRatio < 1;
        },
        { threshold: [1], root: this.$refs.text }
      );
      observer.observe(containerEl);
      this.observer = observer;
    },
  },
};
