import Vue from 'vue';
import { formatDateUTC } from '@/utils/dateFormatter';

function dateFilter(value) {
  if (!value) return '';
  return value === '--' ? value : formatDateUTC(value);
}
Vue.filter('utcDate', dateFilter);

export default dateFilter;
