import Vue from 'vue';
import { formatDate } from '@/utils/dateFormatter';

function dateFilter(value) {
  if (!value) return '';
  return value === '--' ? value : formatDate(value);
}
Vue.filter('date', dateFilter);

export default dateFilter;
