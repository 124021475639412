export default {
  name: 'LinkList',
  props: {
    list: Array,
    loading: Boolean,
    icon: {
      type: String,
      default: 'fa-link',
    },
  },
};
