import ADD_EXPENDITURE_OPTIONS_MUTATION from '@/graphql/mutations/addExpenditureOptions.gql';
import COMPONENTS_OF_NEEDS from '@/graphql/queries/componentsOfNeed.gql';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import CLIENT_QUERY from '@/graphql/queries/client.gql';
import COMPONENTS_AND_OPTIONS from '@/graphql/queries/componentsAndOptions.gql';
import PERMISSIONS from '@/enums/permissions';
import UPDATE_COMPONENTS_OF_NEEDS from '@/graphql/mutations/updateComponentsOfNeed.gql';
import UPDATE_EXPENDITURE_OPTIONS_MUTATION from '@/graphql/mutations/updateExpenditureOptions.gql';
import UPDATE_FUNDING_SOURCES_MUTATION from '@/graphql/mutations/updateFundingSources.gql';
import accessByPermissions from '@/services/userPermissions';
import Btn from '@/components/Btn/Btn.vue';
import BudgetCalculationInfo from '@/components/BudgetCalculationInfo/BudgetCalculationInfo.vue';
import BudgetTable from './BudgetTable/BudgetTable.vue';
import ComponentList from './ComponentList/ComponentList.vue';
import ComponentsReportModal from '@/components/ComponentsReportModal/ComponentsReportModal.vue';
import DataTableColumns from '@/components/DataTableColumns/DataTableColumns.vue';
import DefinitionList from '@/components/DefinitionList/DefinitionList.vue';
import ExpansionPanelHeader from '@/components/ExpansionPanelHeader/ExpansionPanelHeader.vue';
import eventHub from '@/utils/eventHub';
import Modal from '@/components/Modal/Modal.vue';
import FinancialInfo from '@/components/ClientInfo/FinancialInfo/FinancialInfo.vue';
import { cloneDeep, isEqual } from 'lodash';
import { COMPONENT_STATUS } from '@/enums/componentStatus';
import { generateRangeArray } from '@/utils/generator';

const TABLE_UID = 'PLANING_BUDGET_COMPONENTS';
const OPTIONS_RADIO = {
  asExist: 'asExist',
  asNew: 'asNew',
};
const LIST_OF_CHANGEABLE_COLUMNS = [
  'yearForImprovement',
  'unit',
  'conditionAssessment',
  'fundingSource',
  'budgetaryNotes',
  'quantityOfComponents',
  'costPerUnit',
  'averageLifeCycle',
  'componentAge',
  'softCost',
  'customSoftCost',
  'flag',
  'priority',
];

export default {
  name: 'ExpendituresPlanning',
  components: {
    Btn,
    BudgetCalculationInfo,
    DefinitionList,
    Modal,
    ComponentList,
    ExpansionPanelHeader,
    BudgetTable,
    ComponentsReportModal,
    DataTableColumns,
    FinancialInfo,
  },
  props: {
    client: {
      type: Object,
    },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
    componentsOfNeed: {
      query: COMPONENTS_OF_NEEDS,
      variables() {
        return {
          planId: (this.plan && this.plan.id) || null,
          status: COMPONENT_STATUS.OPEN,
        };
      },
      skip() {
        return this.skipQuery || !this.plan || !this.plan.id;
      },
      error(error) {
        this.error = error.networkError ? { ...error, message: 'Something went wrong! Could not load plan components.' } : error;
      },
    },
  },
  data: () => ({
    TABLE_UID,
    currentUser: null,
    componentsOfNeed: null,
    error: null,
    internalLoading: false,
    components: [],
    changes: [],
    selected: [],
    filtered: [],
    expanded: [],
    headers: [],
    selectedHeaders: null,
    selectedYear: null,
    skipQuery: true,
    optionName: '',
    optionNameRules: [v => !!v || 'Name is Required'],
    optionId: null,
    isOptions: false,
    title: '',
    optionsRadioGroup: OPTIONS_RADIO.asNew,
    OPTIONS_RADIO,
    changedBudgetCells: {
      revenue: {},
      startingBallance: {},
    },
    originalBudgetCells: {
      revenue: {},
      startingBallance: {},
    },
    reportFundingSourceInvestmentIds: null,
  }),
  watch: {
    client: {
      handler() {
        const cells = {
          revenue: {},
          startingBallance: {},
        };
        this.client.plans[0]?.financialInfo?.fundingSources?.forEach(source => {
          try {
            if (typeof source.revenue === 'string') {
              cells.revenue[source.id] = JSON.parse(source.revenue);
            }
            if (typeof source.balance === 'string') {
              cells.startingBallance[source.id] = JSON.parse(source.balance);
            }
          } catch (e) {
            console.warn('fundingSources budget table parse error', {
              id: source.id,
              error: e,
            });
          }
        });
        this.changedBudgetCells = cells;
        this.originalBudgetCells = cloneDeep(cells);
      },
      immediate: true,
    },
    componentsOfNeed(value) {
      if (value) {
        this.components = cloneDeep(value);
        // this need to validate changes list with componentsOfNeed in case if componentsOfNeed is updated
        this.validateChanges();
      }
    },
    optionsRadioGroup(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal === OPTIONS_RADIO.asExist) {
          this.optionName = this.title;
        } else {
          this.optionName = '';
        }
      }
    },
  },
  computed: {
    budgetTableComponents() {
      return this.components.map(item => {
        const changed = this.changes.find(changed => changed.id === item.id);
        return changed ? changed : item;
      });
    },
    financialLabels() {
      return [
        {
          label: 'Plan Type',
          value: 'typeOfPlan.name',
        },
        {
          label: 'Plan Start Year',
          value: 'planStartYear',
        },
        {
          label: 'Regional Cost',
          value: 'regionalCost',
          type: 'percentage',
        },
        {
          label: 'Inflation Cost Escalation',
          value: 'inflationCostEscalation',
          type: 'percentage',
        },
        {
          label: 'Soft Cost',
          value: 'softCostPercentage',
          type: 'percentage',
        },
      ];
    },
    isHideReportSlider() {
      return Boolean(this.selected.length || this.filtered.length);
    },
    isNoChangedCells() {
      return isEqual(this.originalBudgetCells, this.changedBudgetCells);
    },
    isNoChanges() {
      return this.changes.length === 0 && this.isNoChangedCells;
    },
    loading() {
      return this.$apollo.queries.componentsOfNeed.loading;
    },
    showGenerateReportBtn() {
      return accessByPermissions(PERMISSIONS.GENERATE_REPORT, this.currentUser);
    },
    canEdit() {
      return accessByPermissions(PERMISSIONS.EDIT_PLAN_COMPONENT, this.currentUser);
    },
    canEditExpenditure() {
      return accessByPermissions(PERMISSIONS.EDIT_EXPENDITURE_OPTIONS, this.currentUser);
    },
    reportFundingSourceOptions() {
      const result = {};
      Object.keys(this.changedBudgetCells.revenue).forEach(sourceId => {
        result[sourceId] = {
          revenue: this.changedBudgetCells.revenue[sourceId],
        };
      });
      Object.keys(this.changedBudgetCells.startingBallance).forEach(sourceId => {
        if (!result[sourceId]) {
          result[sourceId] = {
            // note! startingBalance in backend, but startingBallance in front, the number of letters L is different
            startingBalance: this.changedBudgetCells.startingBallance[sourceId],
          };
        } else {
          result[sourceId].startingBalance = this.changedBudgetCells.startingBallance[sourceId];
        }
      });
      return result;
    },
    reportComponents() {
      return this.selected.length > 0 ? this.selected : this.filtered.length > 0 ? this.filtered : [];
    },
    expenditureOption() {
      return this.changes?.length > 0 ? this.changes : [];
    },
    showBulkYearChangeBtn() {
      return this.filtered.length > 0;
    },
    plan() {
      return this.client?.plans ? this.client?.plans[0] : null;
    },
    planStartYear() {
      return this.plan && this.plan.financialInfo && this.plan.financialInfo.planStartYear
        ? parseInt(this.plan.financialInfo.planStartYear)
        : 0;
    },
    years() {
      if (this.planStartYear) {
        return generateRangeArray(this.planStartYear, this.planStartYear + 19);
      }

      return generateRangeArray(new Date().getFullYear(), new Date().getFullYear() + 19);
    },
  },
  mounted() {
    eventHub.$on('show-option', optionSet => {
      if (this.$refs.main_modal) {
        this.$refs.main_modal.modalOpen = true;
        this.changes = optionSet?.options?.components || [];
        this.changedBudgetCells = optionSet?.options?.table || {
          revenue: {},
          startingBallance: {},
        };
        this.title = optionSet?.name || this.client.name;
        this.optionName = optionSet?.name ?? '';
        this.optionId = optionSet?.id;
        this.isOptions = true;
        this.optionsRadioGroup = OPTIONS_RADIO.asExist;
        this.validateChanges();
      }
    });
  },
  methods: {
    clearChanges() {
      this.error = null;
      this.changes = [];
      this.changedBudgetCells = cloneDeep(this.originalBudgetCells);
      this.selected = [];
      this.filtered = [];
      this.isOptions = false;
      this.title = '';
      this.optionName = '';
      this.optionId = null;
      this.optionsRadioGroup = OPTIONS_RADIO.asNew;
      this.reportFundingSourceInvestmentIds = null;
    },
    validateChanges() {
      if (this.componentsOfNeed) {
        cloneDeep(this.changes).forEach(item => {
          let hasChanges = 0;
          LIST_OF_CHANGEABLE_COLUMNS.forEach(key => {
            const component = this.componentsOfNeed.find(c => item.id === c.id);
            const componentValue = component[key] ?? null;
            const itemValue = item[key] ?? null;

            if (component && !isEqual(componentValue, itemValue)) {
              hasChanges++;
            }
          });
          if (!hasChanges) {
            this.changes = this.changes.filter(c => c.id !== item.id);
          }
        });
      }
    },
    addOption(close) {
      this.$apollo
        .mutate({
          mutation: ADD_EXPENDITURE_OPTIONS_MUTATION,
          variables: {
            input: {
              name: this.optionName,
              planId: this.plan.id,
              options: JSON.stringify({
                components: this.changes,
                table: this.changedBudgetCells,
              }),
            },
          },
        })
        .then(({ data: { addExpenditureOptions } }) => {
          this.$apollo
            .query({
              query: COMPONENTS_AND_OPTIONS,
              variables: {
                planId: this.plan.id,
                status: COMPONENT_STATUS.OPEN,
              },
              fetchPolicy: 'cache-first',
            })
            .then(() => {
              const data = this.readOptionsQuery();
              if (data) {
                data.componentsAndOptions.expenditureOptions.unshift(addExpenditureOptions);
                this.writeOptionsQuery(data);
              }
              eventHub.$emit('show-snackbar', {
                color: 'success',
                text: `Option ${this.optionName} was added successfully!`,
              });
              eventHub.$emit('go-to-tab', 'Expenditures');
              this.$nextTick(() => {
                eventHub.$emit('go-to-expenditures', 2);
              });
              this.error = null;
            });
        })
        .catch(e => {
          this.error = e;
        })
        .finally(() => {
          this.internalLoading = false;
          close();
          this.optionName = '';
        });
    },
    updateOption(close) {
      this.$apollo
        .mutate({
          mutation: UPDATE_EXPENDITURE_OPTIONS_MUTATION,
          variables: {
            input: {
              name: this.optionName,
              id: this.optionId,
              options: JSON.stringify({
                components: this.changes,
                table: this.changedBudgetCells,
              }),
            },
          },
        })
        .then(({ data: { updateExpenditureOptions } }) => {
          const data = this.readOptionsQuery();
          if (data) {
            const index = data.componentsAndOptions.expenditureOptions.findIndex(option => option.id === updateExpenditureOptions.id);
            if (index + 1) {
              this.$set(data.componentsAndOptions.expenditureOptions, index, updateExpenditureOptions);
              this.writeOptionsQuery(data);
            }
          }
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: `Option ${this.optionName} was updated successfully!`,
          });
          eventHub.$emit('go-to-tab', 'Expenditures');
          this.$nextTick(() => {
            eventHub.$emit('go-to-expenditures', 2);
          });
          this.error = null;
        })
        .catch(e => {
          this.error = e;
        })
        .finally(() => {
          this.internalLoading = false;
          close();
          this.optionName = '';
        });
    },
    readOptionsQuery() {
      return this.$apolloProvider.defaultClient.readQuery({
        query: COMPONENTS_AND_OPTIONS,
        variables: {
          planId: this.plan.id,
          status: COMPONENT_STATUS.OPEN,
        },
      });
    },
    writeOptionsQuery(data) {
      return this.$apolloProvider.defaultClient.writeQuery({
        query: COMPONENTS_AND_OPTIONS,
        variables: {
          planId: this.plan.id,
          status: COMPONENT_STATUS.OPEN,
        },
        data,
      });
    },
    onOptionsSubmit(close) {
      if (this.$refs.options_form.validate()) {
        this.internalLoading = true;
        this.optionsRadioGroup === OPTIONS_RADIO.asExist ? this.updateOption(close) : this.addOption(close);
      }
    },
    onModalOpen() {
      if (this.skipQuery) {
        this.skipQuery = false;
      }
    },
    onReset() {
      this.selected = [];
    },
    onSelect(items) {
      this.selected = items;
    },
    onFilter(items) {
      this.filtered = items;
    },
    onChange(item, key) {
      const component = this.componentsOfNeed.find(c => item.id === c.id);
      const index = this.changes.findIndex(c => c.id === item.id);
      const changes = this.changes.slice();
      if (component && !isEqual(component[key], item[key])) {
        if (index >= 0) {
          changes[index] = item;
        } else {
          changes.push(item);
        }
        this.changes = changes;
      } else if (component && index >= 0) {
        let hasChanges = 0;
        LIST_OF_CHANGEABLE_COLUMNS.forEach(key => {
          const componentValue = component[key] || null;
          const itemValue = item[key] || null;
          if (!isEqual(componentValue, itemValue)) {
            hasChanges++;
          }
        });
        if (!hasChanges) {
          this.changes = changes.filter(c => c.id !== item.id);
        }
      }
    },
    onFundingChange(sourceList) {
      this.reportFundingSourceInvestmentIds = sourceList || null;
    },
    async onApplyToPlan(close) {
      this.internalLoading = true;
      await this.saveBudgetTable();
      await this.saveComponents();
      if (close) {
        close();
      }
      this.internalLoading = false;
    },
    onInitHeaders(value) {
      this.headers = value;
    },
    async saveBudgetTable() {
      if (!this.isNoChangedCells) {
        const input = Object.entries(this.changedBudgetCells.revenue).map(item => {
          return {
            id: item[0],
            revenue: JSON.stringify(item[1]),
          };
        });
        for (let fundingSourceId in this.changedBudgetCells.startingBallance) {
          const balance = JSON.stringify(this.changedBudgetCells.startingBallance[fundingSourceId]);
          const item = input.find(item => item.id === fundingSourceId);
          if (item) {
            item.balance = balance;
          } else {
            input.push({
              id: fundingSourceId,
              balance,
            });
          }
        }
        try {
          await this.$apollo.mutate({
            mutation: UPDATE_FUNDING_SOURCES_MUTATION,
            variables: {
              input: input,
            },
            update: (store, { data: { updateFundingSources } }) => {
              let data = store.readQuery({
                query: CLIENT_QUERY,
                variables: { id: this.client.id || null },
              });
              const fundingSources = data.client.plans[0].financialInfo.fundingSources.slice();
              fundingSources.forEach(item => {
                const updatedSource = updateFundingSources.find(source => source.id === item.id);
                if (updatedSource) {
                  item = Object.assign({}, item, updatedSource);
                }
              });
              data.client.plans[0].financialInfo.fundingSources = fundingSources;
              store.writeQuery({
                query: CLIENT_QUERY,
                variables: { id: this.client.id || null },
                data: data,
              });
            },
          });
          this.originalBudgetCells = cloneDeep(this.changedBudgetCells);
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: `Clients & Components Budget was updated successfully!`,
          });
        } catch (error) {
          this.error = error;
        }
      }
    },
    async saveComponents() {
      if (this.changes.length) {
        const input = this.changes.map(item => ({
          id: item.id,
          facilityId: (item.facility && item.facility.id) || null,
          systemId: (item.system && item.system.id) || null,
          componentId: (item.component && item.component.id) || null,
          status: item.status,
          yearCompleted: item.yearCompleted,
          actualCost: item.actualCost,
          description: item.description,
          componentUrl: item.componentUrl,
          yearForImprovement: item.yearForImprovement,
          quantityOfComponents: item.quantityOfComponents,
          unitId: (item.unit && item.unit.id) || null,
          costPerUnit: item.costPerUnit,
          conditionAssessmentId: (item.conditionAssessment && item.conditionAssessment.id) || null,
          priority: item.priority,
          locationOfComponent: item.locationOfComponent,
          fundingSourceInvestmentId: (item.fundingSource && item.fundingSource.id) || null,
          averageLifeCycle: item.averageLifeCycle,
          componentAge: item.componentAge,
          budgetaryNotes: item.budgetaryNotes,
          internalNotes: item.internalNotes,
          componentNotes: item.componentNotes,
          softCost: item.softCost,
          customSoftCost: item.customSoftCost,
          flag: item.flag,
        }));

        try {
          await this.$apollo.mutate({
            mutation: UPDATE_COMPONENTS_OF_NEEDS,
            variables: {
              input: input,
            },
            update: (store, { data: { updateComponentsOfNeed } }) => {
              // Read the data from our cache for this query.
              const data = store.readQuery({
                query: COMPONENTS_OF_NEEDS,
                variables: { planId: (this.plan && this.plan.id) || null, status: COMPONENT_STATUS.OPEN },
              });
              updateComponentsOfNeed.forEach(updated => {
                const index = data.componentsOfNeed.findIndex(c => c.id === updated.id);
                if (index >= 0) {
                  data.componentsOfNeed[index] = updated;
                }
              });
              store.writeQuery({
                query: COMPONENTS_OF_NEEDS,
                variables: { planId: (this.plan && this.plan.id) || null, status: COMPONENT_STATUS.OPEN },
                data,
              });
            },
          });
          this.changes = [];
          eventHub.$emit('go-to-tab', 'Expenditures');
          this.$nextTick(() => {
            eventHub.$emit('go-to-expenditures', 0);
          });
        } catch (error) {
          this.error = error;
        }
      }
    },
    confirmBudgetPlanning() {
      if (this.changes.length && this.$refs.confirm_modal) {
        this.$refs.confirm_modal.modalOpen = true;
      } else {
        this.closeBudgetPopup();
      }
      eventHub.$emit('to-to-dashboard');
    },
    closeBudgetPopup() {
      this.$refs.main_modal && (this.$refs.main_modal.modalOpen = false);
      this.clearChanges();
      this.onReset();
      this.components = cloneDeep(this.componentsOfNeed);
    },

    bulkUpdateComponentsYear(year) {
      this.$refs.component_list.massChage('yearForImprovement', year);
      this.$refs.component_list.setFilterValue('yearForImprovement', [year]);
    },
  },
};
