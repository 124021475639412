import LinkList from '@/components/LinkList/LinkList.vue';

export default {
  name: 'FileList',
  components: { LinkList },
  props: {
    list: Array,
    loading: Boolean,
  },
};
